import * as Sentry from '@sentry/nuxt'

const { release, sentryDsn: dsn } = useAppConfig()

if (dsn) {
  Sentry.init({
    release,
    dsn,
    integrations: [
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ['at-managetimeline'],
        behaviour: 'drop-error-if-contains-third-party-frames',
      }),
    ],
  })
}
